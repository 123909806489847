const url = "https://node-mobile-payment.mobiloitte.org/api/v1";
// http://182.72.203.245:1829/api/v1/admin/userList
const ApiConfig = {
  login: `${url}/admin/loginAdmin`,
  otp: `${url}/admin/otpSent`,
  verifyOtp: `${url}/admin/verifyOtp`,
  metricList: `${url}/admin/metricList`,
  particularMetric: `${url}/admin/particularMetric`,
  metricAdd: `${url}/admin/metricAdd`,
  resetPassword: `${url}/admin/​resetPassword`,
  getTotalDeposit: `${url}/admin/getTotalDeposit`,
  getTotalWithdrawAmount: `${url}/admin/getTotalWithdrawAmount`,
  logout: `${url}/admin/logOut`,
  // getTotalDeposit: `${url}/admin/getTotalDeposit`,
  // getTotalWithdrawAmount: `${url}/admin/getTotalWithdrawAmount`,
  withdrawList: `${url}/admin/withdrawList`,
  acceptWithdrawRequest: `${url}/admin/acceptWithdrawRequest`,
  rejectWithdrawRequest: `${url}/admin/rejectWithdrawRequest`,
  particularWithdraw: `${url}/admin/particularWithdraw`,
  reviewWithdrawList: `${url}/admin/reviewWithdrawList`,
  adminUserTransaction:`${url}/admin/adminUserTransaction`,

  viewProfile: `${url}/admin/viewProfile`,
  editProfile: `${url}/admin/editProfile`,

  changePassword: `${url}/admin/changePassword`,

  // product
  addProductList:`${url}/admin/addProduct`,
  // addProduct: `${url}​/admin/addProduct`,
  viewProduct: `${url}/admin/viewProduct`,
  // addProduct: `${url}​/admin/addProduct`,
  getProductList: `${url}/admin/productList`,
  editProduct: `${url}/admin/editProduct`,

  //kyc
  getAllKycDetail: `${url}/admin/getAllKycDetails`,
  // ​    rejectedKyc:`${url}/rejectedKycByAdmin`,
  getPaticularKyc: `${url}/admin/getKycDetails`,
  approveKycByAdmin: `${url}/admin/approveKycByAdmin`,
  rejectedKyc: `${url}/admin/rejectedKycByAdmin`,
  getKycDetailParticular:`${url}/admin/getKycDetailsParticularUser`,

  //    User
  getUserList: `${url}/admin/userList`,
  addUser: `${url}​/admin/addUser`,
  deleteuser: `${url}/admin/delete`,
  blockuser: `${url}/admin/blockUnblock`,
  edituser: `${url}/admin/editUser`,
  viewParticularUser:`${url}/admin/viewParticularUser`,
  // product
  addProduct: `${url}​/addProduct`,
  getProductList: `${url}/admin/productList`,
  viewProduct: `${url}/admin/viewProduct`,
  editProduct: `${url}/admin/editProduct`,
  toggleBlockProduct: `${url}/admin/blockUnblockProduct`,
  deleteProduct: `${url}/admin/deleteProduct`,

  // Wallet
  transactionList:`${url}/admin/transactionList`,
  viewParticularUserTransactionList:`${url}/admin/viewParticularUserTransactionList`,

  //FAQ

  editFaqs: `${url}/admin/editFaqs`,
  addFaq: `${url}/admin/addFaq`,
  // getFaq:`${url}/`

  //Static
  staticcontent: `${url}/admin/getStaticContent`,
  updatecontent: `${url}/admin/updateStaticContent`,
  //    User
  getUserList: `${url}/admin/userList`,
  addUser: `${url}/admin/addUser`,

  //earninglist
  particularEarning: `${url}/admin/particularEarning`,
  earninglist: `${url}/admin/earningList`,
  particularEarningList:`${url}/admin/getEarningListParticularUser`,

  //getcount
  getcount: `${url}/admin/getCount`,

  //currencylist
  currencyList:`${url}/admin/currencyList`,
    
 //investmentlist
 investmentListUsers:`${url}/admin/investmentListUsers`,
//viewInvestment
viewInvestment:`${url}/admin/viewInvestment`,
// viewCurrency

currencyView:`${url}/admin/currencyView`,
// investmentaccept
acceptInvestment:`${url}/admin/acceptInvestment`,
// investmentreject
rejectInvestment:`${url}/admin/rejectInvestment`,

};

export default ApiConfig;
