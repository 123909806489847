import _ from "lodash";
import { colors, createMuiTheme, responsiveFontSizes } from "@material-ui/core";
import typography from "./typography";

const baseOptions = {
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: "hidden",
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: "rgba(0,0,0,0.075)",
      },
    },
    MuiInputBase: {
      root: {
        "& > input[type=number]": {
          "-moz-appearance": "textfield",
          "&::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
          "&::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
          },
        },
      },
    },

    MuiTabs: {
      root: {
        "& .MuiTabs-flexContainer": {
          display: "flex",
          justifyContent: "space-around",
        },
      },
    },
    MuiDataGrid: {
      root: {
        border: "none",

        "& .MuiDataGrid-colCell": {
          color: "#3c8dbc",
        },
        "& .MuiDataGrid-colCellTitle": {
          color: "#ffff",
        },
        "&  .MuiDataGrid-columnsContainer ": {
          backgroundColor: "#252d47",
        },
        border: "none",
        "& .MuiDataGrid-cellRight": {
          textAlign: "initial",
        },
        "& .MuiDataGrid-columnSeparator": {
          display: "none",
        },
        ".MuiDataGrid-columnsContainer ": {
          backgroundColor: "aliceblue",
        },

        "& .MuiDataGrid-colCellRight .MuiDataGrid-colCellTitleContainer": {
          justifyContent: "flex-start",
        },
        "& .MuiDataGrid-main": {
          minWidth: "1200px",
        },
      },
    },
    MuiButton: {
      contained: {
        boxShadow: "none",

        "&:hover": {
          boxShadow: "none",
        },
      },
      outlined: {
        boxShadow: "none",

        borderWidth: 2,
      },
      outlinedPrimary: {
        borderRadius: 27,
        boxShadow: "none",
      },
      containedSizeLarge: {
        color: "#ffffff",
        padding: "14px 50px",
      },
    },
  },
};

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "Poppins",
  },
  palette: {
    type: "light",
    action: {
      primary: "#20509e",
    },
    background: {
      default: "#FBFBFD",
      dark: "#f5f5f5",
      paper: colors.common.white,
    },
    primary: {
      main: "#252d47",
      dark: "#333366",
      light: "#e2e3fa",
    },
    secondary: {
      main: "#ff0610",
    },
    warning: {
      main: "#ffae33",
      dark: "#ffae33",
      light: "#fff1dc",
    },
    success: {
      main: "#54e18c",
      dark: "#54e18c",
      light: "#e2faec",
    },
    error: {
      main: "#ff7d68",
      dark: "#ff7d68",
      light: "#ffe9e6",
    },
    text: {
      primary: "#52565c",
      secondary: "#999999",
    },
    common: {
      black: "#222222",
    },
  },
};

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
