/* eslint-disable no-use-before-define */
import React, { useEffect, useState, useContext } from "react";
import clsx from "clsx";
import { useLocation, matchPath } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import Logo from "src/component/Logo";
import Modal from "react-modal";
import { AuthContext } from "src/context/Auth";

import { useHistory } from "react-router-dom";
import axios from "axios";
import ApiConfig from "src/config/APIConfig";

import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
  ListItem,
  Button,
  Link,
  Divider,
} from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { PieChart as PieChartIcon } from "react-feather";

import NavItem from "./NavItem";
import { size } from "lodash-es";
const sections = [
  {
    items: [
      {
        title: "DASHBOARD",
        icon: PieChartIcon,
        href: "/dashboard",
      },
      {
        title: "USER MANAGEMENT",
        icon: PieChartIcon,
        href: "/user",
      },
      {
        title: "KYC MANAGEMENT",
        icon: PieChartIcon,
        href: "/kyc",
      },
      {
        title: "PRODUCT MANAGEMENT",
        icon: PieChartIcon,
        href: "/product",
      },
      {
        title: "METRIC MANAGEMENT",
        icon: PieChartIcon,
        href: "/metric",
      },
      {
        title: "EARNING MANAGEMENT",
        icon: PieChartIcon,
        href: "/earningManagement",
      },
      {
        title: "WITHDRAWAL  MANAGEMENT",
        icon: PieChartIcon,
        href: "/withdrawel",
        component: "Accordion",
        items: [
          {
            title: "REVIEW WITHDRAWAL",
            icon: PieChartIcon,
            href: "/review",
          },
          {
            title: "WITHDRAWAL HISTORY",
            icon: PieChartIcon,
            href: "/withdrawelHistory",
          },
        ],
      },
      {
        title: "WALLET MANAGEMENT",
        icon: PieChartIcon,
        href: "/wallet",
      },
      {
        title: "INVESTMENT MANAGEMENT",
        icon: PieChartIcon,
        href: "/investment-management",
      },
      {
        title: "CURRENCY MANAGEMENT",
        icon: PieChartIcon,
        href: "/currency-management",
      },




      {
        title: "STATIC CONTENT MANAGEMENT",
        icon: PieChartIcon,
        href: "/static",
        items: [
          {
            title: "ABOUT US",
            icon: PieChartIcon,
            href: "/aboutUs",
          },
          {
            title: "PRIVACY POLICY",
            icon: PieChartIcon,
            href: "/policy",
          },
          {
            title: "TERMS & CONDITIONS",
            icon: PieChartIcon,
            href: "/tnc",
          },
        ],
      },
      {
        title: "FAQ  MANAGEMENT",
        icon: PieChartIcon,
        href: "/faq",
      },
      
      // {
      //   title: "LOGOUT",
      //   icon: PieChartIcon,
      //   href: "/",
      // },
    ],
  },
];

function renderNavItems({ items, ...props }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...props }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth = 0,
  isNested = false,
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <div style={{ paddingLeft: 8 }}>
        <NavItem
          depth={depth}
          icon={item.icon}
          info={item.info}
          key={key}
          open={Boolean(open)}
          title={
            <div style={{ width: 150, textAlign: "left" }}>{item.title}</div>
          }
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items,
            isNested: true,
          })}
        </NavItem>
      </div>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={
          <div style={{ width: isNested ? "auto" : 150, textAlign: "left" }}>
            {item.title}
          </div>
        }
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: theme.palette.primary.main,
  },
  desktopDrawer: {
    width: 256,
    top: 80,
    height: "calc(100% - 132px)",
    background: theme.palette.primary.main,
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  buttonLeaf: {
    color: "#969ba1",
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    borderLeft: "solid 8px transparent",
    borderRadius: 0,
    "&:hover": {
      background: theme.palette.secondary.main,
      color: "#fafafa",
    },
    "&.depth-0": {
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  icon: {
    display: "flex",
    alignItems: "flex-start",
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(-1),
  },
  title: {
    marginRight: "auto",
  },
  button_style: {
    width: 100,
  },
  overlay: {
    position: "fixed",
    width: "100%",
    height: "100%",
    top: 0,
    // width:0,
    left: 0,
    right: 0,
    bottom: 0,

    backgroundColor: "transparent",
  },
  content: {
    position: "absolute",
    top: 200,
    left: 500,
    right: 500,
    bottom: 180,
    height: 200,
    border: "1px solid #ccc",
    background: "#fff",
    overflow: "auto",
    WebkitOverflowScrolling: "touch",
    borderRadius: 5,
    outline: "none",
    padding: "20px",
    opacity: "none",
  },
  active: {
    color: "#fafafa",
    borderColor: theme.palette.secondary.main,
    fontWeight: theme.typography.fontWeightRegular,
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: "#fafafa",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const [logout, setlogout] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [iserror, setIserror] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    name:"",
  });
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const auth = useContext(AuthContext);
  const logOut = async () => {
    setIsSubmit(true);
    try {
      setIsUpdating(true);
      const accessToken = window.localStorage.getItem("creatturAccessToken");
       console.log("token",accessToken)
      const response = await axios.put(ApiConfig.logout,{},{ 
        headers: {
          token:`${accessToken}`,
        },
      });
      console.log(response);
      if (response.data.responseCode !== 200) {
        setIserror(true);
        setIsSuccess(false);
        setAlertMsg(response.data.responseMessage);
      } else {
        setIsSuccess(true);
        setIserror(false);
        history.push("/");
        setAlertMsg(response.data.responseMessage);
      }
      setIsUpdating(false);
    } catch (err) {
      console.log("ERROR", err);
      setIsUpdating(false);
    }
  
    // history.push("/login");
  
  // console.log("Log out");
  // auth.userLogIn(false, null);
  // history.push("/");
};

const accessToken = window.localStorage.getItem("creatturAccessToken");
console.log("profile Token", accessToken);
useEffect(() => {
  // setIsLoading(true);
  axios
    .get(
      ApiConfig.viewProfile,

      {
        headers: {
          token: `${accessToken}`,
        },
      }
    )
    .then((response) => {
      // console.log("data",response);
      if (response.data.responseCode !== 200) {
        setIserror(true);
        setIsSuccess(false);
        setAlertMsg(response.data.responseMessage);
      } else {
        setIsSuccess(true);
        setIserror(false);
        // setIsLoading(false);
        setIsUpdating(false);
        let result = response.data.result;
        console.log("data", result);
        let { name, phoneNumber, newProfilePic } = response.data.result;

        setUserData({
          email: result.email,
         name: result.name,
        });
        // setCode(response.data.result.countryCode);
        //SettingsInputAntennaTwoTone
        console.log("result", result);
      }
    })
    .catch((response) => {
      setIsUpdating(false);

      console.log("response", response);
    });
}, []);
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box py={4}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              <Box style={{ color: "#ffff", textAlign: "center" }} mb={4}>
               <Link href="/editProfile">
                <Logo
                  width="180"
                  style={{ cursor: "pointer", paddingLeft: 16 }}
                />
                </Link>
                <Typography variant="h4">{userData.name}</Typography>
                <Typography variant="h4">{userData.email}</Typography>
              </Box>
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
          <List style={{ padding: 0, marginTop: -10 }}>
            <ListItem className={clsx(classes.item)}>
              <Button
                onClick={handleClickOpen}
                activeClassName={classes.active}
                className={clsx(classes.buttonLeaf)}
              >
                <ExitToAppIcon size="small" className={classes.icon} />
                <span style={{ fontSize: 17 }}>{"Logout"}</span>
              </Button>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Do you want to log out?"}
                </DialogTitle>

                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button onClick={logOut} color="primary" autoFocus>
                    Log Out
                  </Button>
                </DialogActions>
              </Dialog>

              {/* <Modal
                isOpen={logout}
                onClose={false}
                onRequestClose={() => setlogout(false)}
                className={classes.content}
                overlayClassName={classes.overlay}
              >
                <Box>
                  <Box justifyContent="center" display="flex">
                    <Typography variant="h4">Logout?</Typography>
                  </Box>
                  <Divider style={{ marginTop: 10 }} />
                  <Box justifyContent="center" display="flex" mt={3}>
                    <Typography>Are you sure you want to logout?</Typography>
                  </Box>
                  <Box justifyContent="center" display="flex" mt={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button_style}
                      onClick={() => history.push("/")}
                    >
                      Yes
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button_style}
                      onClick={() => setlogout(false)}
                      style={{ marginLeft: 10 }}
                    >
                      No
                    </Button>
                  </Box>
                </Box>
              </Modal> */}
            </ListItem>
          </List>
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
