import React, { useContext,useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { AuthContext } from "src/context/Auth";
import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Container,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Logo from "src/component/Logo";
import ApiConfig from "src/config/APIConfig";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
  },
  toolbar: {
    height: 80,
  },

  link: {
    fontWeight: theme.typography.fontWeightMedium,
    "& + &": {
      marginLeft: theme.spacing(2),
    },
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} color="default" {...rest}>
      <Toolbar className={classes.toolbar}>
        <TopBarData />
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
};
TopBar.defaultProps = {
  onMobileNavOpen: () => {},
};

export default TopBar;

export function TopBarData() {
  const history = useHistory();
  const auth = useContext(AuthContext);
  const [iserror, setIserror] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const logOut = async () => {
      setIsSubmit(true);
      try {
        setIsUpdating(true);
        const accessToken = window.localStorage.getItem("creatturAccessToken");
         console.log("token",accessToken)
        const response = await axios.put(ApiConfig.logout,{},{ 
          headers: {
            token:`${accessToken}`,
          },
        });
        console.log(response);
        if (response.data.responseCode !== 200) {
          setIserror(true);
          setIsSuccess(false);
          setAlertMsg(response.data.responseMessage);
        } else {
          setIsSuccess(true);
          setIserror(false);
          history.push("/");
          setAlertMsg(response.data.responseMessage);
        }
        setIsUpdating(false);
      } catch (err) {
        console.log("ERROR", err);
        setIsUpdating(false);
      }
    
      // history.push("/login");
    
    // console.log("Log out");
    // auth.userLogIn(false, null);
    // history.push("/");
  };

  const web = new WebSocket(
    "wss://notification.logistic.mobiloitte.com/notification"
  );
  web.onopen = () => {
    const dataToSend = {
      userId: auth.userData.userId,
      notificationUserType: auth.userData.role.role,
    };
    web.send(JSON.stringify(dataToSend));
    web.onmessage = async (event) => {
      console.log("data", event);
      auth.updateNotifications(auth.userData.role.role);
    };
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Container
        maxWidth="xl"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <Logo
          width="180"
          onClick={() => history.push("/dashboard")}
          style={{ cursor: "pointer", paddingLeft: 16 }}
        />

        <Button
          onClick={handleClickOpen}
          variant="contained"
          color="primary"
          style={{
            height: 40,
          }}
        >
          Logout
        </Button>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Do you want to log out?"}
          </DialogTitle>

          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={logOut} color="primary" autoFocus>
              Log Out
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}
