import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoginLayout from 'src/layouts/LoginLayout';
export const routes = [
  {
    exact: true,
    path: '/',
    layout: LoginLayout,
    component: lazy(() => import('src/views/auth/LogIn')),
  },
  {
    exact: true,
    path: '/login',
    // guard:true,
    layout: LoginLayout,
    component: lazy(() => import('src/views/auth/LogIn/index')),
  },
  {
    exact: true,
    path: '/forgotPassword',
    // guard:true,
    layout: LoginLayout,
    component: lazy(() => import('src/views/auth/LogIn/ForgotPassword')),
  },
  {
    exact: true,
    path: '/editProfile',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/Profile')),
  },
  {
    exact: true,
    path: '/dashboard',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/Dashboard')),
  },
  {
    exact: true,
    path: '/user',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/UserManagement')),
  },
  {
    exact: true,
    path: '/changePassword',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/ChangePassword')),
  },
  {
    exact: true,
    path: '/updateUser',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/UserManagement/updateUser')),
  },
  {
    exact: true,
    path: '/addUser',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/UserManagement/AddUser')),
  },
  {
    exact: true,
    path: '/viewUser',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/UserManagement/viewUser')),
  },

  {
    exact: true,
    path: '/kyc',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/KycManagement')),
  },

  {
    exact: true,
    path: '/viewKYC',
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/KycManagement/ViewKYC')),
  },
  {
    exact: true,
    path: '/viewKYCTwo',
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/UserManagement/Components/ViewKYCTwo')),
  },
  {
    exact: true,
    path: '/editKYC',
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/UserManagement/Components/EditKYC')),
  },
  {
    exact: true,
    path: '/product',
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/ProductManagement')),
  },
  {
    exact: true,
    path: '/addProduct',
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/ProductManagement/AddProduct')),
  },
  {
    exact: true,
    path: '/viewProduct',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/ProductManagement/viewProduct')),
  },
  {
    exact: true,
    path: '/EarningView',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/EarningManagement/EarningView')),
  },
  {
    exact: true,
    path: '/ViewWithdrawl',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/ReviewWithdrawl/ViewWithdrawl')),
  },
  {
    exact: true,
    path: '/ViewWithdrawlHistory',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/WithdrawelHistory/ViewWithdrawlHistory')),
  },
  {
    exact: true,
    path: '/editProduct',
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/ProductManagement/editProduct')),
  },
  {
    exact: true,
    path: '/metric',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/MetricManagement')),
  },
  {
    exact: true,
    path: '/metricTab',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/MetricManagement/index')),
  },
  {
    exact: true,
    path: '/earningManagement',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/EarningManagement')),
  },
  {
    exact: true,
    path: '/userEarning',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/UserManagement/Components/UserEarning')),
  },
  {
    exact: true,
    path: '/viewEarning',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/UserManagement/Components/ViewEarning')),
  },
  {
    exact: true,
    path: '/viewMatric',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/MetricManagement/ViewMatric')),
  },
  {
    exact: true,
    path: '/viewHistory',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/UserManagement/Components/viewHistory')),
  },
  {
    exact: true,
    path: '/WalletHistory',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/WalletManagement/walletHistory')),
  },
  {
    exact: true,
    path: '/withdrawel',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/WithdrawelManagement')),
  },
  {
    exact: true,
    path: '/wallet',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/WalletManagement')),
  },

  {
    exact: true,
    path: '/addWallet',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/WalletManagement/AddWallet')),
  },
  {
    exact: true,
    path: '/editWallet',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/WalletManagement/EditWallet')),
  },


  {
    exact: true,
    path: '/static',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/StaticContent')),
  },
  {
    exact: true,
    path: '/review',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/ReviewWithdrawl')),
  },
  {
    exact: true,
    path: '/withdrawelHistory',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/WithdrawelHistory')),
  },
  {
    exact: true,
    path: '/walletTransactionHistory',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/UserManagement/Components/WalletHistory')),
  },
  {
    exact: true,
    path: '/addWalletHistory',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/UserManagement/Components/AddWalletHistory')),
  },
  {
    exact: true,
    path: '/depositWallet',
    
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/UserManagement/Components/depositWallet')),
  },
  {
    exact: true,
    path: '/depositHistory',
    
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/UserManagement/Components/DepositHistory')),
  },
  {
    exact: true,
    path: '/withdrawCurrency',
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/UserManagement/Components/WithdrawCurrency')),
  },
  {
    exact: true,
    path: '/walletWithdrawelHistory',
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/UserManagement/Components/WalletWithdrawelHistory')),
  },
  {
    exact: true,
    path: '/withdrawelDetails',
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/UserManagement/Components/withdrawelDetails')),
  },
  {
    exact: true,
    path: '/depositDetails',
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/UserManagement/Components/DepositDetails')),
  },
  {
    exact: true,
    path: '/faq',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/FAQ')),
  },
  {
    exact: true,
    path: '/aboutUs',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/AboutUs')),
  },
  {
    exact: true,
    path: '/policy',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/PrivacyPolicy')),
  },
  {
    exact: true,
    path: '/tnc',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/TermsAndCondition')),
  },
  {
    exact: true,
    path: '/currency-management',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/CurrencyManagement/CurrencyList')),
  },
  {
    exact: true,
    path: '/investment-management',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/InvestmentManagement/InvestmentList')),
  },
  {
    exact: true,
    path: '/view-investment',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/InvestmentManagement/ViewInvestment')),
  },
  {
    exact: true,
    path: '/view-currency',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/CurrencyManagement/ViewCurrency')),
  },
  {
    exact: true,
    path: '/edit-currency',
    // guard:true,
    layout: DashboardLayout,
    component: lazy(() => import('src/views/pages/CurrencyManagement/EditCurrency')),
  },



  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFound')),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
